<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return '283e87ce-a0c9-4e58-8d53-c4d8363e2f4b'
    }
  }
}
</script>
